.container {
  width: 95%;
  max-width: 1200px;
  margin: 0 auto;
}
.select-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
  .ant-select {
    width: 100%;
  }
}
.nav {
  width: 100%;
  padding: 1.65rem 0;
  img {
    max-width: 190px;
  }
}
.bg-dark {
  background: rgba(31, 34, 41, 0.85);
  box-shadow: 0 3px 12px 0 rgb(0 0 0 / 30%);
}
.main {
  margin: 2rem auto;
}
.ant-select {
  margin: 1rem 0 1rem auto;
  max-width: 700px;
  display: block;
}
.ant-select-selector {
  border-radius: 5px;
}
.ant-collapse {
  display: block;
  border-radius: 5px;
}
.ant-collapse-header-text {
  font-weight: 600;
}
.ant-select-dropdown {
  .ant-select-item {
    margin-bottom: 2px;
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background: rgba(#1ca8dd, 0.15);
    color: #1ca8dd;
    .ant-select-item-option-state {
      color: #1ca8dd;
    }
  }
}
.ant-select-multiple {
  .ant-select-item-option-active {
    background: rgba(#1ca8dd, 0.15);
    color: #1ca8dd;
  }
  .ant-select-selection-item {
    background: rgba(#1ca8dd, 0.15);
    color: #1ca8dd;
    border-color: rgba(#1ca8dd, 0.15);
  }
  .ant-select-selection-item-remove {
    color: #1ca8dd;
  }
}
.ant-tag {
  background: rgba(#1ca8dd, 0.15);
  color: #1ca8dd;
  border-color: rgba(#1ca8dd, 0.15);
  cursor: pointer;
  margin: 0.25rem 0.25rem;
}
.ant-table-content {
  margin: 1rem auto;
  border-radius: 5px;
  border: 1px solid #f0f0f0;
}
th.ant-table-cell {
  padding: 9px 16px !important;
  border-bottom: 1px solid #f0f0f0 !important;
}
.ant-table-cell {
  border-bottom: none !important;
}
.ant-table-cell-row-hover {
  background: initial !important;
  border-bottom: none !important;
}
.ant-popover-title {
  padding-top: 6px;
  padding-bottom: 4px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  font-size: 15px;
}
.ant-popover-title a {
  color: #ff9900;
  text-decoration: none;
}
.ant-popover-title a:hover {
  text-decoration: underline;
}
.packages-content {
  max-height: 300px;
  overflow: auto;
  padding-left: 13px;
  font-size: 15px;
}
.packages-content a {
	color: #888;
	text-decoration: underline;
}
.packages-content a:hover {
	text-decoration: none;
}