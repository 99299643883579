@use "node_modules/antd/dist/reset.css";

@font-face {
  font-family: "Nunito";
  src: url("./assets/fonts/NunitoSans.ttf") format("truetype");
}

* {
  font-family: "Nunito", sans-serif;
}

body {
  height: 100vh;
  width: 100%;
}

strong {
  font-weight: 500;
}

ul {
  list-style-type: disc;
}

.list-small {
  font-size: 90%;
}
